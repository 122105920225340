@mixin text-decoration-none {
  text-decoration: none;

  @include hover-focus-active {
    text-decoration: none;
  }
}

@mixin absolute-color($color) {
  color: $color;

  @include hover-focus-active {
    color: $color;
  }
}

@mixin decrease-opacity($color, $opacity: .8) {
  @include text-decoration-none;
  @include absolute-color($color);

  transition: opacity .3s ease;
  opacity: 1;

  @include hover-focus-active {
    opacity: $opacity;
  }
}

@mixin list-unstyled {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

@mixin font($font-family, $font-weight, $font-style: normal) {
  // font-size はレスポンシブで可変が必要なので指定しない。
  // ウィンドウサイズが変わっても不変な値だけ指定
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: $font-style;
}

@mixin button-cleared {
  background: none;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}

// font-size, line-height => 上下の合計値
@function get-xd-spacing-size($spacing-size, $font-size, $line-height) {
  @return $spacing-size - ( $line-height - $font-size ) / 2;
}

// ボタン内で ローディングインジケーターを表示するときのスタイル
@mixin loading-button {
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: $transparent;

  &:hover,
  &:focus,
  &:active {
    color: $transparent;
  }
}

@mixin drop-down-icon {
  position: relative;

  &::before {
    content: "";
    background-image: url('../../public/images/common/arrow_drop_down_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 5.5px;
    right: 0;
    pointer-events: none;
  }
}

@mixin required {
  &::after {
    content: " *";
    font-weight: $font-weight-bold;
    color: $red3;
  }
}

@mixin mypage-container-width {
  margin: 0 auto;
  width: map_get($mypage-container-max-widths, xl);

  @include media-breakpoint-down(lg) {
    width: map_get($mypage-container-max-widths, lg);
  }

  @include media-breakpoint-down(md) {
    width: map_get($mypage-container-max-widths, md);
    padding: 0 15px;
  }
}
