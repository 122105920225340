@import '~draft-js/dist/Draft.css';

.public-DraftEditor-content {
  min-height: 120px;
  padding: 15px;
  margin: 0 -15px -15px;
}

.public-DraftEditorPlaceholder-root {
  padding: 15px;
  margin: 0 -15px -15px;
  color: $gray4;
}
