html {
  scroll-behavior: smooth;
}

body {
  background-color: $white;
  color: $black1;
}

p {
  font-size: .875rem;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

a {
  @include text-decoration-none;
  @include decrease-opacity($black1);
}

li {
  font-size: .875rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

ul {
  list-style: disc;
}

// Bootstrap のコンテナ
.container {
  @include make-container();
  @include make-container-max-widths();
}

.img-fluid {
  width: 100%;
  height: auto;
}

// ============================================================
// next-page-transitions
// ============================================================
.page-transition-enter {
  opacity: 0;
}

.page-transition-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.page-transition-exit {
  opacity: 1;
}

.page-transition-exit-active {
  opacity: 0;
  transition: opacity .3s;
}
